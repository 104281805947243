import React from "react"
import "resources/style/style.less"
import Seo from "../components/seo"
import Layout from "components/layout"
import PrivacyPolice from "components/section/privacy-police"

const Privacy = () => {
  return (
    <>
      <Seo title={"Privacy Policy Wispay"} />
      <Layout headerVariant="blue" headerBackground="blue">
        <PrivacyPolice />
      </Layout>
    </>
  )
}

export default Privacy
